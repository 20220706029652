<template>
  <div
    v-if="show || firstIn"
    class="filter-ctn__side-filter"
  >
    <header class="side-filter__header">
      <i
        class="suiiconfont sui_icon_nav_close_24px"
        aria-label="close"
        role="text"
        @click="closePanel"
      ></i>
      <h3
        :adaflag="language.SHEIN_KEY_PWA_15044"
        aria-hidden="true"
        :aria-label="language.SHEIN_KEY_PWA_15044"
      > 
        {{ language.SHEIN_KEY_PWA_15044 }} 
      </h3>
    </header>
    <main class="side-filter__main">
      <s-loading
        v-if="!componentReady"
        :show="true"
        type="curpage"
        :cur-page-scroll-fix="false"
      />
      <SideFilterGroup 
        ref="SideFilterGroup"
        :show="show"
        :menu-bar-config="menuBarConfig"
        :menu-items-config="menuItemsConfig"
        :show-left-filter="showLeftFilter"
        :left-echo-num="leftEchoNum"
        :first-in="firstIn"
      >
        <SideEachItem
          v-for="(menuBar, index) in menuBarConfig"
          :key="menuBar.key || menuBar.text"
          :index="index"
          :menu-bar="menuBar"
          :menu-item="menuItemsConfig[index]"
          :side-show="show"
          :reset="reset"
          :language="language"
          :show-title-right="!showLeftFilter"
          :disabled="loading"
          @selectChange="selectChange"
          @handleMultiLineDone="handleMultiLineDone"
          @showItemAll="showItemAll"
        />
      </SideFilterGroup>
    </main>
    <footer class="side-filter__footer">
      <BottomOperation
        :sum="sum"
        :language="language"
        :close-left="closeBottomLeft"
        :loading="loading"
        @reset="resetAll"
        @done="done"
        @componentReady.once="componentReady = true"
      />
    </footer>
  </div>
</template>

<script>
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
import { useEmitEvent } from '../utils'
import { getIsCustomAttr } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
const { emitEvent } = useEmitEvent()
export default defineComponent({
  name: 'SideFilter',
  components: {
    BottomOperation: defineAsyncComponent(() => import(/* webpackChunkName: "plv2_SideFilter" */'./UI/BottomOperation.vue')),
    SideEachItem: defineAsyncComponent(() => import(/* webpackChunkName: "plv2_SideFilter" */'./SideEachItem.vue')),
    SideFilterGroup: defineAsyncComponent(() => import(/* webpackChunkName: "plv2_SideFilter" */'./SideFilterGroup.vue')),
    SLoading,
  },
  emits: ['update:resetData', 'update:needInit', 'side-change'],
  props: {
    sum: {
      type: [String, Number],
      default: 0
    },
    menuBarConfig: {
      type: Array,
      default: () => []
    },
    menuItemsConfig: {
      type: Array,
      default: () => []
    },
    resetData: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    showLeftFilter: {
      type: Boolean,
      default: false
    },
    leftEchoNum: {
      type: Boolean,
      default: false
    },
    needInit: {
      type: Boolean,
      default: false
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    // 是否是泛列表, 泛列表有特殊的逻辑
    isProductList: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      reset: false,
      firstIn: false,
      componentReady: false,
      report: false
    }
  },
  computed: {
    filterNum () {
      let filterNum = 0
      this.menuItemsConfig.forEach(item => {
        if (item.type == 'single') {
          // 查看标签是否存在
          const hasTag = item.data.data.some(tag => tag.tag_id === item.curValue)
          if (item.data.curValue && hasTag) {
            filterNum += 1
          }
        } else if (item.type == 'multi' || item.type == 'img') {
          let selectedNum = 0
          const selectedMulti = item.selectedMulti || []
          const data = item.data.data

          if (item.data.selectedNum || item.data.selectedNum === 0) {
            selectedNum += (item.data.selectedNum || 0)
          } else {
            if (!selectedMulti.length) return

            data.forEach(item => {
              if (item.mix_value.split('-').some(mix_value => selectedMulti.includes(mix_value))) {
                selectedNum += 1
              }
            })
          }

          filterNum += selectedNum
        } else if (item.type == 'price') {
          // startMinPrice和startMaxPrice必须都为字符串
          const { startMinPrice = '', startMaxPrice = '' } = item.data
          filterNum += (String(startMinPrice) && String(startMaxPrice)) ? 1 : 0
        } else if (item.type === 'tsp') {
          let selectedNum = 0
          const selectedMulti = item.selectedMulti || []
          const { data } = item.data
          if (item.data.selectedNum || item.data.selectedNum === 0) {
            selectedNum += (item.data.selectedNum || 0)
          } else {
            if (!selectedMulti.length) return
            data.forEach(item => {
              if (selectedMulti.includes(item.id)) {
                selectedNum += 1
              }
            })
          }

          filterNum += selectedNum
        }
      })

      return filterNum
    },
    type() {
      return this.getContainerRequest().type
    }
  },
  inject: {
    getContainerRequest: {
      // 可能容器内没有 provide getContainerRequest 函数。比如店铺？
      default: () => () => ({}) // fallback to a function returning an empty object
    }
  },
  watch: {
    needInit: {
      handler() {
        if (this.needInit) {
          this.firstIn = false
          this.show = false
          this.$emit('update:needInit', false)
        }
      },
      immediate: true
    },
    type(value) {
      if (value === 'refresh') {
        this.report = true
      }
      if (value && this.show) {
        this.report = false
        this.exposeCloudTag()
      }
    },
    show (value) {
      if (!this.firstIn) {
        this.firstIn = true
        this.exposeCloudTag()
      }
      if (value && this.report) {
        this.report = false
        this.exposeCloudTag()
      }
    },
    filterNum: {
      handler (newVal, oldVal) {
        if (this.isProductList) return
        if (newVal === 0 && typeof oldVal === 'undefined') return

        nextTick(() => {
          emitEvent(this, 'setFilterBadgeNum', newVal)
          emitEvent(this, 'needHighlightNavBar', { highlight: !!newVal })
        })
      },
      immediate: true
    },
    resetData: {
      handler (isReset) {
        if (isReset) {
          this.resetAll(true)
          this.$emit('update:resetData', false)
        }
      },
      immediate: true
    }
  },
  mounted () {
    appEventCenter?.on('refreshAnalysisData', this.onRefreshAnalysisData)
  },
  onUnmounted() {
    appEventCenter?.off('refreshAnalysisData', this.onRefreshAnalysisData)
  },
  methods: {
    onRefreshAnalysisData() {
      this.report = true
    },
    exposeCloudTag() {
      const shopTypeData = this.menuItemsConfig.find(item => item.dataType === 'shopType')?.data?.data || []
      const cloudTags = shopTypeData.map((item, index) => ({
        index: index + 1,
        isPicNavCate: false,
        tagCate: 'label',
        value: item.tag_id
      }))
      // 云标签曝光事件应该在 pv 之后，确保上报数据无误。而 container 中的 pv 是延时 2s 上报的
      setTimeout(() => {
        analysis.exposeCloudTag({
          cloudTags: cloudTags,
          clickType: 'filter'
        })
      }, 2020)
    },
    closePanel () {
      emitEvent(this, 'closeSidePanel')
    },
    resetAll (propsCalled) {
      this.resetFilterNum()
      this.resetStatus(true)
      emitEvent(this, 'resetAll', {
        operation: 'resetAll',
        menuItems: this.menuItemsConfig,
        outClick: propsCalled
      })

      nextTick(() => {
        this.resetStatus(false) // 重置初始状态，以便下次可以出发watch
      })
      this.$refs.SideFilterGroup?.reset()
    },
    done () {
      emitEvent(this, 'clickDone')
    },
    selectChange (payload, index) {
      const { type, curSelectedItem = {} } = payload
      const { active, mix_value } = curSelectedItem || ''

      // 整合所有已选择的筛选项
      if (['multi', 'img'].includes(type)) {
        const multiData = this.menuItemsConfig.filter(item => (['multi', 'img'].includes(item.type)))
        const selectedMultiItems = multiData.reduce((curr, next) => {
          let actives = []
          if (getIsCustomAttr(next.data)) {
            next.data.data.forEach(item => {
              actives = actives.concat((item.data).filter(subItem => subItem.active))
            })
          } else {
            actives = (next.data.data).filter(subItem => subItem.active)
          }
          // 当前取消操作时候，过滤相同的id
          !active && (actives = actives.filter(item => item.mix_value !== mix_value))
        
          return actives.length ? curr.concat(actives) : curr
        }, [])

        Object.assign(payload, { data: selectedMultiItems })
      } else if (type === 'tsp') {
        const multiData = this.menuItemsConfig.filter(item => (item.type == 'tsp'))
        const selectedMultiItems = multiData.reduce((curr, next) => {
          const actives = (next.data.data).filter(subItem => subItem.active)
          return actives.length ? curr.concat(actives) : curr
        }, [])
        Object.assign(payload, { tspData: selectedMultiItems })
      }
      emitEvent(this, 'sidePanelChange', payload)
      this.$refs.SideFilterGroup?.selectChange(payload, index) // 左侧筛选透传
    },
    setPanelShowStatus (isShow) {
      this.show = isShow

      this.$emit('side-change', isShow)
    },
    resetStatus (status) {
      this.reset = status
    },
    resetFilterNum () {
      this.menuItemsConfig.forEach(item => {
        if (item.type == 'single') {
          item.data.curValue = ''
        } else if (['multi', 'img', 'tsp'].includes(item.type)) {
          item.data.selectedNum = 0
          item.selectedMulti = []
        } else if (item.type == 'price') {
          item.data = Object.assign({}, item.data, { startMinPrice: '', startMaxPrice: '' })
        }
      })
    },
    handleMultiLineDone() {
      this.$refs.SideFilterGroup?.itemMultiLineDone()
    },
    showItemAll(menuItem) {
      this.$refs.SideFilterGroup?.showItemAll(menuItem)
    }
  },
})
</script>

<style lang="less">
.filter-ctn {
  &__side-filter {
    width: 8.84rem;
    .side-filter {
      &__header {
        position: absolute;
        top: 0;
        width: 100%;
        padding: .28rem .32rem;
        .font-dpr(34px);
        text-align: center;
        .border-dpr(border-bottom, 1px, #e5e5e5);
        background-color: #fff;
        z-index: @zindex-hack;
        [class*="iconfont"] {
          position: absolute;
          top: 50%;
          left: .32rem;
          transform: translateY(-50%);
          .font-dpr(34px);
        }
        h3 {
          /* rw:begin */
          font-family:'Adieu';
        }
      }
      &__main {
        padding: 1.2rem 0 1.6rem;
        overflow: auto;
        height: 100%;
        width: 100%;
      }
      &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 .32rem;
        background-color: #fff;
        z-index: @zindex-hack;
      }
    }
  }
}
</style>
