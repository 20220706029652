<template>
  <div class="type-promotion">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...$attrs.queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="saInfo"
      :config="config"
      :footer-type="footerType"
      :feeds-type="feedsType"
      :is-bff="isBff"
      :get-business-cart-foot="getBusinessCartFoot"
      @update-cart="handleUpdateCart"
    >
      <template #header>
        <HeadAccessory 
          v-if="typeStatus.isAccessory"
          ref="HeadAccessory"
          :promotion="promotion"
          :type-status="typeStatus"
          @pick="handlePick"
        />
        <HeadBundleSave
          v-else-if="typeStatus.isBundleSave"
          :promotion="promotion"
          :language="language"
          :type-status="typeStatus"
          :selectId="promotionIncludeTspIds"
          @tab-click="onTabClick"
        />
        <PromotionHead
          v-else
          :promotion="promotion"
          :type-status="typeStatus"
        />
      </template>
      <template #footer>
        <BusinessCartFoot 
          v-if="isBusinessCartBottom"
          ref="BusinessCartFoot"
          type="promotion"
          scene="promotion"
          :promotion-id="promotion.promotion_id"
          :state="saInfo.state"
          :tips="popupInfo.progressTip"
          :status="addOnStatus"
          :auto-use-coupon="autoUseCoupon"
          :auto-use-point="autoUsePoint"
          :feeds-type="feedsType"
          :custom-theme-vars="customThemeVars"
          :add-item-type="addItemType"
          :config="config"
          :lure-info="bffIncentiveInfo"
          @checkoutClick="viewCart"
          @cartUpdated="hanldeBusinessCartUpdated"
          @re-pick="handlePick"
        >
          <template #progress>
            <ProgressBar
              transparent
              animation
              autoUpdate
              :thresholds="progressList"
            />
          </template>
        </BusinessCartFoot>
        <CommonFoot
          v-else
          ref="CommonFoot"
          :add-item-type="addItemType"
          :progress-tip="popupInfo.progressTip"
          :btn-text="popupInfo.goToCheckoutTip"
          :thresholds="progressList"
          :equity-tip="popupInfo.equityTip"
          :lure-info="bffIncentiveInfo"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick } from 'vue'
import { useStore } from 'vuex'
import { getRangeStatus, getTypeStatus } from 'public/src/pages/common/add-on/utils/index.js'
import { EventUpdateBusinessCart, EventSetProgressPromise, EventPickItem, EventViewCart, EventListReset  } from '../../utils/event.js'
import AddOnItem from '../AddOnItem.vue'
import PromotionHead from './Head/index.vue'
import HeadAccessory from './HeadAccessory/index.vue'
import HeadBundleSave from './HeadBundleSave/index.vue'
import CommonFoot from './CommonFoot.vue'
import BusinessCartFoot from 'public/src/pages/common/add-on/comps/business-cart-foot/index.vue'
import ProgressBar from 'public/src/pages/common/add-on/comps/base/ProgressBar.vue'
import { getPromotionInfo } from '../../utils/fetcher.js'

export default defineComponent({
  name: 'TypePromotion',
  components: {
    AddOnItem,
    PromotionHead,
    HeadAccessory,
    CommonFoot,
    BusinessCartFoot,
    ProgressBar,
    HeadBundleSave,
  },
  props: {
    promotion: {
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    triggerPromotionFetch: {
      type: Boolean,
      default: false,
    },
    isBff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowRangeUpAnimation: false, // 是否展示过升档气泡
      isShowProgressUpAnimation: false, // 是否展示过进度提升气派
      couponChange: '0',

      isShowAutoPick: false, // 是否已经自动弹出过
      selectIncludeTspIds: '', // 当前的选品id
      lureInfo: null,
    }
  },
  setup() {
    const { state, getters } = useStore()
    const abtInfo = computed(() => state.abtInfo)
    const language = computed(() => state.language)
    const progressDataPromise = computed(() => state.progressDataPromise)
    const promotionBottomStyle = computed(() => getters.promotionBottomStyle)
    const feedsType = computed(() => getters.feedsType)
    const autoUseCoupon = computed(() => getters.autoUseCoupon)
    const autoUsePoint = computed(() => getters.autoUsePoint)
    const jsonRuleId = computed(() => getters.jsonRuleId)
    return {
      abtInfo,
      language,
      progressDataPromise,
      promotionBottomStyle,
      feedsType,
      autoUseCoupon,
      autoUsePoint,
      jsonRuleId,
    }
  },
  computed: {
    addItemType() {
      return this.popupInfo?.addItemType
    },
    typeStatus() {
      return getTypeStatus(this.promotion.type_id, this.promotion.promotion_logo_type)
    },
    customThemeVars() {
      if (this.typeStatus.isAccessory || this.typeStatus.isBundleSave) return {}
      const vars = {
        '--bsc-nsc-header-title-color': '#000', // 标题颜色
        '--bsc-nsc-header-title-doubt-color': '#222', // 标题问号颜色
        '--bsc-nsc-close-icon-color': '#666', // 关闭按钮颜色
        '--bsc-nsc-applicable-group-header-background': 'transparent', // 活动分组背景
        '--bsc-nsc-delete-dialog-title-color': '#222', // 删除挽留弹窗主标题颜色
        '--bsc-nsc-delete-dialog-close-icon-color': '#222', // 删除挽留弹窗关闭 "x", icon 颜色
      }
      if (this.isNewUser) {
        vars['--bsc-nsc-header-title-color'] = '#FFFDD8' // 标题颜色
        vars['--bsc-nsc-header-title-doubt-color'] = '#fff' // 标题问号颜色
        vars['--bsc-nsc-close-icon-color'] = '#fff' // 关闭按钮颜色
        vars['--bsc-nsc-applicable-group-header-background'] = 'linear-gradient(183deg, #FFD0D0 2.79%, #FFF 59.78%), linear-gradient(183deg, #FFF4CD 2.79%, #FFF 59.78%)' // 活动分组背景
        vars['--bsc-nsc-delete-dialog-title-color'] = '#fff' // 删除挽留弹窗主标题颜色
        vars['--bsc-nsc-delete-dialog-close-icon-color'] = '#fff' // 删除挽留弹窗关闭 "x", icon 颜色
      }
      return vars
    },
    saInfo () {
      return this.$attrs?.saInfo || {}
    },
    popupInfo() {
      return this.promotion?.promotionPopupInfo || {}
    },
    promotionIncludeTspIds: {
      get() {
        if (this.selectIncludeTspIds) return this.selectIncludeTspIds
        if (this.typeStatus.isBundleSave) {
          const groups = this.popupInfo.bundleBuyGroup || []
          const uncheckedTab = groups.find(item => item.isChecked != '1')
          return uncheckedTab?.includeTspIds || groups[0]?.includeTspIds
        }
        return this.popupInfo?.includeTspIds
      },
      set(val) {
        this.selectIncludeTspIds = val
      }
    },
    anotherInfo() {
      return {
        goodsPrice: ((this.promotion?.diffMoney?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        includeTspId: this.promotionIncludeTspIds,
        jsonRuleId: this.jsonRuleId
      }
    },
    isBusinessCartBottom() {
      return !this.config?.isCloseBusinessCartEntry
    },
    footerType() {
      if (this.isBusinessCartBottom) return 'business-cart'
      return 'normal'
    },
    progressList() {
      if (this.popupInfo.thresholds) return this.popupInfo.thresholds
      return [{ progressPercent: this.popupInfo?.progressPercent || 0 }]
    },
    addOnStatus() { // 凑单状态: 0 - 全部未满足; 1 - 全部满足; 2 - 部分满足
      const { range, isMeetMaxLimit, infiniteGrade, thresholds, progressPercent } = this.popupInfo
      if (!thresholds?.length) return '-'
      if (infiniteGrade == '1') {
        return range > 0 ? (isMeetMaxLimit ? '1' : '2') : '0'
      }
      if (!thresholds) return progressPercent == 1 ? '1' : '0'
      return range > 0 ? (range == thresholds.length ? '1' : '2') : '0'
    },
    isNewUser() {
      return this.popupInfo.style == '1'
    },
    incentivePointInstence() {
      return this.$refs.BusinessCartFoot?.incentivePointInstance || this.$refs.CommonFoot?.incentivePointInstance || {}
    },
    bffIncentiveInfo() {
      return {
        isBff: this.isBff,
        lureInfo: this.lureInfo,
      }
    }
  },
  emits: ['update-promotion'],
  watch: {
    'progressList': {
      handler(newV, oldV) {
        const { isUp, isChange, isRangeUp, preRangeIndex } = getRangeStatus(newV, oldV)

        const autoShowPickDrawer = this.typeStatus.isAccessory && !this.isShowAutoPick && isRangeUp && preRangeIndex == 0

        if (autoShowPickDrawer) {
          this.isShowAutoPick = true
          this.handlePick({ isAuto: true })
        }

        if (isChange) {
          // 更新进度和气泡
          this.progressDataPromise?.resolve?.({
            bubbleText: autoShowPickDrawer ? '' : this.getBubbleText({ isRangeUp, isUp }),
          })
        }

        if (!this.selectIncludeTspIds) {
          this.selectIncludeTspIds = this.promotionIncludeTspIds
        }
      },
      deep: true,
    },
    'triggerPromotionFetch': {
      handler: async function() {
        try {
          if(this.isBff){
            await this.fetchPromotionInfo()
            nextTick(() => {
              EventListReset.notify()
            })
          }
        } catch (error) {
          
        }
      },
      immediate: true
    }
  },
  methods: {
    async fetchPromotionInfo() {
      const params = {
        popupInfoId: this.promotion?.promotion_id,
        addItemType: 'promotion',
        lurePointCacheInfo: this.incentivePointInstence?.lurePointCacheInfo || '',
        excludeBubbles: Array.from(this.incentivePointInstence?.excludeBubbles || []).join(',') || '',
      }
      const res = await getPromotionInfo(params)
      if(res?.code == 0) {
        const popupInfo = res?.info?.promotionPopupInfo
        this.lureInfo = res?.info?.lureInfo
        this.$emit('update-promotion', {
          promotionPopupInfo: popupInfo,
          promotion_id: popupInfo?.promotionId,
          type_id: popupInfo?.typeId,
          promotion_logo_type: popupInfo?.promotionLogoType,
          diffMoney: popupInfo?.diffMoney,
          coupon_num: popupInfo?.couponNum,
        })
      }

    },
    getSaExtraData() {
      const extraData = {
        promotion_code: this.promotion?.promotion_id,
        coupon_change: this.couponChange,
        is_satisfied: this.addOnStatus,
        newuser_label: this.popupInfo.biData?.isNewUserReturnCoupon,
      }
      if (this.promotion.coupon_num) {
        extraData.is_couponbag = this.promotion.coupon_num > 1 ? '1' : '0'
      }
      if (this.typeStatus.isAccessory) {
        extraData.action = this.popupInfo.range != 0 ? 'pick' : 'view'
      }
      return extraData
    },
    getBubbleText({ isRangeUp, isUp }) {
      if (!isUp) return '' // 无提升,不谈出气泡
      if (this.isShowRangeUpAnimation) return '' // 展示过升档气泡就不再弹气泡了

      if (isRangeUp) {
        this.couponChange = '1'
        this.isShowRangeUpAnimation = true
        return this.popupInfo.upgradingBubbleTip
      }

      if (this.isShowProgressUpAnimation) return ''
      this.isShowProgressUpAnimation = true
      return this.popupInfo?.bubbleTip
    },
    getBusinessCartFoot() {
      return this.$refs.BusinessCartFoot
    },
    handlePick(info) {
      EventPickItem.notify({ 
        promotionId: this.promotion?.promotion_id,
        fromType: info?.isAuto ? 'addItem-auto' : 'addItem',
      })
    },
    hanldeBusinessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        EventSetProgressPromise.notify()
        EventUpdateBusinessCart.notify() // 通知外层调用方
        if(this.isBff){
          this.fetchPromotionInfo()
        }
      }
    },
    viewCart(data) {
      if (this.$refs.HeadAccessory?.needRetain) {
        this.$refs.HeadAccessory.toggleDrawer?.(true)
        return
      }
      EventViewCart.notify(data)
    },
    handleUpdateCart() {
      this.$refs.BusinessCartFoot?.refetchCarts()
      if(this.isBff){
        this.fetchPromotionInfo()
      }
    },
    onTabClick(includeTspId) {
      this.promotionIncludeTspIds = includeTspId
      nextTick(() => {
        EventListReset.notify({ reset: true })
      })
    },
  },
})
</script>
<style lang="less" scoped>
.type-promotion {
  height: 100%;
}
</style>
