<template>
  <transition 
    name="header-animate"
  >
    <div 
      v-if="!isFullScreen"
      class="promotion-head"
      :style="halfScreenStyle"
    >
      <div class="header-content">
        <div 
          v-if="popupInfo.topStickyText"
          class="tag" 
        >
          <span class="tag__text">{{ popupInfo.topStickyText }}</span>
        </div>
        <div class="left">
          <p class="title">
            {{ halfScreenTip }}
          </p>
          <p
            v-if="timeStamp > 0"
            class="countdown"
          >
            <span class="ends-in">{{ popupInfo.endTip }}</span>
            <CountDown
              :time-stamp="timeStamp"
            />
          </p>
        </div>
        <div class="right">
          <component 
            :is="rightComp.name"
            v-bind="rightComp.binds"
          />
        </div>
      </div>
      <CloseIcon
        class="close-icon"
        size="14"
        :color="closeColor"
      />
    </div>
    <FullScreenHead
      v-else
      :tips="fullScreenTip"
      :style-info="fullScreenStyle"
    />
  </transition>
</template>

<script>
import { computed, defineComponent, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'PromotionHead',
  components: {
    CountDown: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CountDown.vue')),
    CouponOne: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./CouponOne.vue')),
    CouponMore: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./CouponMore.vue')),
    CouponOneNewUser: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./CouponOneNewUser.vue')),
    CouponMoreNewUser: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./CouponMoreNewUser.vue')),
    XMoneyNItem: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./XMoneyNItem.vue')),
    FullReduce: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./FullReduce.vue')),
    FullDiscount: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./FullDiscount.vue')),
    CloseIcon: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CloseIcon.vue')),
    FullScreenHead: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/FullScreenHead.vue')),
  },
  props: {
    typeStatus: {
      type: Object,
      default: () => ({}),
    },
    promotion: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { state } = useStore()
    const isFullScreen = computed(() => state.isFullScreen)
    return {
      isFullScreen,
    }
  },
  computed: {
    popupInfo() {
      return this.promotion?.promotionPopupInfo || {}
    },
    timeStamp() {
      return +this.popupInfo.endTimestamp
    },
    closeColor() {
      return this.isNewUser ? '#fff' : '#666'
    },
    rightComp() {
      if (this.typeStatus.isReturnCoupon) {
        return this.promotion.coupon_num > 1 ? {
          name: this.isNewUser ? 'CouponMoreNewUser' : 'CouponMore',
          binds: {
            title: this.popupInfo.couponTitle,
            desc: this.popupInfo.couponDesc,
          },
        } : {
          name: this.isNewUser ? 'CouponOneNewUser' : 'CouponOne',
          binds: {
            title: this.popupInfo.couponTitle,
            desc: this.popupInfo.couponDesc,
            label: this.popupInfo.couponLabel,
          }
        }
      }
      if (this.typeStatus.isXMoneyNItem) {
        return { name: 'XMoneyNItem' }
      }
      if (this.typeStatus.isFullReduce) {
        return { 
          name: 'FullReduce',
          binds: { tip: this.popupInfo.iconTips  },
        }
      }
      if (this.typeStatus.isDiscount) {
        return { 
          name: 'FullDiscount',
          binds: { tip: this.popupInfo.iconTips  },
        }
      }
      return {}
    },
    // 订单返券相关
    isNewUser() {
      return this.popupInfo.style == '1'
    },
    isCouponBundle() { // 返券包 or 返券
      return this.promotion.coupon_num > 1
    },
    halfScreenTip() {
      return  this.popupInfo.topTitle || this.popupInfo.couponTip
    },
    halfScreenStyle() {
      return {
        '--var-bg': `url(${this.popupInfo.backgroundImage})`,
        '--title-color': this.isNewUser ? '#FFF' : '#461200',
        '--title-shadow': this.isNewUser ? '0px 2px 2px rgba(201, 0, 0, 0.32)' : '',
      }
    },
    fullScreenTip() {
      return this.popupInfo.topTitle || this.popupInfo.topTip
    },
    fullScreenStyle() {
      return this.isNewUser ? {
        '--head-bg': `url('https://img.ltwebstatic.com/images3_ccc/2024/03/28/b9/1711610934b71303833e92586bed952557bf662e2c.png')`,
        '--tip-color': '#FFF',
        '--tip-text-shadow': '0px 2px 2px rgba(201, 0, 0, 0.32)',
      } : {
        '--head-bg': `url('http://img.ltwebstatic.com/images3_acp/2024/10/28/0c/173010773937d88b0188b87a38bbf18ed60cd37408.png')`,
        '--tip-color': '#461200',
        '--close-color': '#666',
      }
    }
  },
})
</script>

<style lang="less" scoped>
.promotion-head {
  height: 102/37.5rem;
  overflow: hidden;
  position: relative;
  background-size: cover;
  border-radius: 12/37.5rem 12/37.5rem 0 0;
  background-image: var(--var-bg);
  .close-icon {
    position: absolute;
    top: 4/37.5rem;
    right: 8/37.5rem;
  }
}
.header-content {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 102/37.5rem;
  direction: ltr;
  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 3/37.5rem;
    width: 100/37.5rem;
    height: 22/37.5rem;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/03/28/25/17116109332f360cd9d2bb5f494597ed1304478747.png') no-repeat;
    background-size: contain;
    overflow: hidden;
    .tag__text {
      font-size: 12px;
      color: @sui_color_white;
      font-weight: bold;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      max-width: 92/37.5rem;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100%; 
    padding: 22/37.5rem 0rem 14/37.5rem 20/37.5rem;
    .title {
      font-size: 16px;
      line-height: 20/37.5rem;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp:2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      color: var(--title-color);
      text-shadow: var(--title-shadow);
    }
    .countdown {
      margin-top: 4/37.5rem;
      display: flex;
      align-items: center;
    }
    .ends-in {
      margin-right: 5/37.5rem;
    }
  }
  .right {
    height: 100%;
    flex-shrink: 0;
  }
}

.header-animate-enter-active,.header-animate-leave-active { /* stylelint-disable-line */
  transition: all .2s;
}
.header-animate-enter-from,
.header-animate-leave-to { /* stylelint-disable-line */
  height: 0;
  opacity: .6;
}
</style>
