// import { TextResizePlugin } from '../scui'
import 'public/src/services/eventCenterV2/'
import { useSaDirective } from '@shein-aidc/basis-sa-event-center'
export { tap, tap as vTap, expose, expose as vExpose, useSaDirective } from '@shein-aidc/basis-sa-event-center'

export default {
  install(app) {
    // app.use(TextResizePlugin)
    useSaDirective(app)
  }
}
