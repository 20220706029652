<template>
  <s-drawer
    :visible="visible"
    class="container-drawer"
    size="65%"
    max-size="65%"
    :closeIconStyle="{
      fontSize: '15px'
    }"
    :show-close="true"
    @update:visible="$emit('close')"
  >
    <template #title>
      <div></div>
    </template>
    <div class="error-disable">
      <img 
        class="image" 
        :src="imgSrc"
      />
      <p class="tips">
        {{ statusMsg }}
      </p>
    </div>
  </s-drawer>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { defineComponent } from 'vue'
import { EventExposeAddCart } from 'public/src/pages/common/add-on/utils/event.js'
const { PUBLIC_CDN } = gbCommonInfo

export default defineComponent({
  name: 'ErrorDisable',
  components: {
    SDrawer,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({})
    },
    statusMsg: {
      type: String,
      default: ''
    },
  },
  computed: {
    imgSrc() {
      return `${PUBLIC_CDN}/pwa_dist/images/add-on/sui_img_conveniencestore-abffb1d2d1.png`
    }
  },
  mounted() {
    EventExposeAddCart.notify({
      service_error: 'promotion_disable_error',
    })
  },
})
</script>

<style lang="less" scoped>
.container-drawer {
  :deep(.sui-drawer__container) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  :deep(.sui-drawer__header) {
    border-bottom: none;
    min-height: unset;
    z-index: @zindex-header;
  }
  :deep(.sui-drawer__body) {
    height: 100%;
  }
  .error-disable {
    padding: 180/75rem 24/75rem 24/75rem;
    text-align: center;
    .image {
      width: 150/75rem;
      height: 150/75rem;
    }
    .tips {
      margin-top: 24/75rem;
      color: #767676;
      font-size: 14px;
    }
  }
}
</style>
