<template>
  <div 
    class="head-accessory"
    :class="{ 'full-screen': isFullScreen }"
    :style="styleInfo"
  >
    <div class="header-content">
      <p 
        class="title"
        :data-text="popupInfo.topTitle"
      >
        {{ popupInfo.topTitle }}
      </p>
      <div
        v-show="!isFullScreen"
        v-if="rangeList.length > 1"
        class="ranges" 
      >
        <div
          v-for="(item, idx) in rangeList"
          :key="item.value"
          class="rule"
        > 
          <span class="box">
            <img
              height="16"
              width="16"
              :src="item.goodsImg"
            />
            <span>{{ item.value }}</span>
          </span>
          <img
            v-if="idx < rangeList.length - 1"
            class="divider"
            src="http://img.ltwebstatic.com/images3_acp/2024/10/31/3f/173036079999817992975c334d6d0aec5f4c87841b.png"
          />
        </div>
      </div>
      <section class="gift-boxs">
        <div 
          class="pick-area"
          @click="handlePick"
        >
          <img
            height="14"
            width="14"
            :src="icoImg"
          />
          <p class="tips">
            {{ attachmentInfo.attachmentTips }}
          </p>
          <GiftImgs
            :list="attachmentInfo.attachmentProductBasicInfos || []"
          />
          <div class="btn">
            {{ attachmentInfo.buttonTips }}
          </div>
        </div>
      </section>
    </div>
    <div class="count-wrapper">
      <CountDown
        v-if="timeStamp"
        :end-tip="popupInfo.endTip"
        :time-stamp="timeStamp"
      />
    </div>
    <CloseIcon
      class="close-icon"
      size="14"
      color="#fff"
      :close-inside="false"
      @close="handleClose"
    />
    <AttentionDrawer
      :visible="drawerShow"
      :tips="attachmentInfo.retainTitle"
      :confirmText="attachmentInfo.retainChooseButtonTips"
      :giveUpText="attachmentInfo.retainGiveUpButtonTips"
      :canPickProducts="attachmentInfo.attachmentProductBasicInfos || []"
      @close="toggleDrawer(false)"
      @giveUp="handleGiveUp"
      @pick="handlePick"
    />
  </div>
</template>

<script>
import { computed, defineComponent, defineAsyncComponent, nextTick } from 'vue'
import { useStore } from 'vuex'
import { EventClose } from 'public/src/pages/common/add-on/utils/event.js'

export default defineComponent({
  name: 'HeadAccessory',
  components: {
    CloseIcon: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CloseIcon.vue')),
    GiftImgs: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./GiftImgs.vue')),
    CountDown: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./CountDown.vue')),
    AttentionDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'./AttentionDrawer.vue')),
  },
  props: {
    typeStatus: {
      type: Object,
      default: () => ({}),
    },
    promotion: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { state } = useStore()
    const isFullScreen = computed(() => state.isFullScreen)
    return {
      isFullScreen,
    }
  },
  data() {
    return {
      drawerShow: false,
    }
  },
  computed: {
    popupInfo() {
      return this.promotion?.promotionPopupInfo || {}
    },
    attachmentInfo() {
      return this.promotion?.promotionPopupInfo.attachmentInfo || {}
    },
    timeStamp() {
      return +this.popupInfo.endTimestamp
    },
    rangeList() {
      return this.attachmentInfo.rangeValues || []
    },
    icoImg() {
      return this.typeStatus?.isFreeGift ? 'http://img.ltwebstatic.com/images3_acp/2024/10/31/38/1730361453851f416c79004b870856506e3edfb250.png' : 'http://img.ltwebstatic.com/images3_acp/2024/10/31/42/1730361231ee41884a4539beb77de49d5499aaad7d.png'
    },
    styleInfo() {
      return {
        '--var-bg': this.typeStatus?.isSheinClubGift ? 'url(http://img.ltwebstatic.com/images3_acp/2024/09/11/63/1726035231e73b70f5152a2f6b0bff96d75c3ca0eb.png)' : 'url(/pwa_dist/images/add-on/addon_accessory_head_bg-e4874991b7.png)',
        '--title-color': this.isNewUser ? '#FFF' : '#461200',
        '--title-shadow': this.isNewUser ? '0px 2px 2px rgba(201, 0, 0, 0.32)' : '',
      }
    },
    needRetain() {
      return this.attachmentInfo.existAttachment != '1' && this.popupInfo.range > 0 && this.attachmentInfo.attachmentProductBasicInfos?.length
    },
    // 订单返券相关
    isNewUser() {
      return this.popupInfo.style == '1'
    },
  },
  methods: {
    handlePick() {
      this.toggleDrawer(false)
      this.$emit('pick')
    },
    handleClose() {
      if (this.needRetain) {
        this.toggleDrawer(true)
        return
      }
      EventClose.notify()
    },
    toggleDrawer(bool) {
      this.drawerShow = bool
    },
    handleGiveUp() {
      this.toggleDrawer(false)
      nextTick(() => {
        EventClose.notify()
      })
    },
  },
})
</script>

<style lang="less" scoped>
.head-accessory {
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    border-radius: 14px 14px 0 0;
    background-image: var(--var-bg);
  }

  &.full-screen::before { 
    border-radius: 0;
    background-position: bottom;
  }
  .close-icon {
    position: absolute;
    top: 4/37.5rem;
    right: 8/37.5rem;
    z-index: 11;
  }
  .count-wrapper {
    position: absolute;
    right: 1px;
    top: -24px;
  }
}
.header-content {
  padding: 15/37.5rem 12/37.5rem 12/37.5rem;
  position: relative;
  overflow: hidden;
  height: 100%;
  z-index: 10;
  direction: ltr;
  .base-title-style {
    font-weight: bold;
    font-size: 16px;
    height: 19px;
    line-height: 19px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    position: relative;
    width: 95%;
    margin: 0 auto 10/37.5rem;
    .base-title-style();
    color: #fff;
    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      .base-title-style();
      color: #FFDE9F;
      -webkit-mask: linear-gradient(to bottom, transparent 20%, #000);
    }
  }
  .ranges {
    width: 100%;
    margin-bottom: 12/37.5rem;
    display: flex;
    justify-content: center;
    overflow: hidden;
    .rule {
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    .box {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.10);
      color: #fff;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.30);
      padding: 1px 6px 1px 4px;
      line-height: 16px;
      span {
        margin-left: 1/37.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .divider {
      height: 1px;
      width: 16/37.5rem;
      margin: 0 3/37.5rem;
    }
  }
  .gift-boxs {
    width: 100%;
    border-radius: 8px;
    border: .5px solid #fff;
    padding: 6/37.5rem 12/37.5rem;
    background: linear-gradient(92deg, rgba(255, 81, 42, 0.20) -18.32%, rgba(255, 255, 255, 0.14) 16.31%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(172deg, #FFC69A 3.17%, #FFF 97.16%);
  }
  .pick-area {
    display: flex;
    align-items: center;
    height: 35/37.5rem;
    .tips {
      flex: 1;
      font-size: 14px;
      margin-left: 4/37.5rem;
      margin-right: 12/37.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .btn {
      display: flex;
      padding: 4/37.5rem 12/37.5rem;
      margin-left: 8/37.5rem;
      color: #fff;
      font-weight: bold;
      border-radius: 28/37.5rem;
      background: linear-gradient(90deg, #EB4521 0%, #FF6C23 100%);
    }
  }
}
</style>
