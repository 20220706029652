<template>
  <div
    class="free-shipping-header"
    :class="{
      'free-shipping-header_full-screen': fullScreen,
    }"
  >
    <CloseIcon
      size="16"
      color="#fff"
      class="free-shipping-header__close"
      :class="{
        'free-shipping-header__close_full-screen': fullScreen,
      }"
    />
    <section class="free-shipping-header__content">
      <div
        class="free-shipping-header__tips"
        :class="{
          'free-shipping-header__tips_full-screen': fullScreen,
        }"
        v-html="promotion.topTitle"
      >
      </div>
      <div
        v-show="timeStamp > 0 && !fullScreen"
        class="free-shipping-header__countdown"
      >
        <span class="countdown__label">{{ promotion.endTip }}</span>
        <CountDown
          :time-stamp="timeStamp"
        />
      </div>
    </section>
    <img
      v-show="!fullScreen"
      class="free-shipping-header__img"
      :src="headerCartImg"
    />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue'
export default defineComponent({
  name: 'FreeShippingHeader',
  components: {
    CountDown: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CountDown.vue')),
    CloseIcon: defineAsyncComponent(() => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CloseIcon.vue')),
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    promotion: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    headerBg() {
      return `url(${this.promotion?.backgroundImage}) no-repeat center top/100% 2.72rem`
    },
    headerCartImg() {
      return this.promotion?.iconBackgroundImage
    },
    timeStamp() {
      return +this.promotion?.endTimestamp
    },
  },
})
</script>

<style lang="less" scoped>
.free-shipping-header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 13/37.5rem 0 20/37.5rem;
  width: 100%;
  height: 102/37.5rem;
  transition: all 0.2s;
  border-radius: 12/37.5rem 12/37.5rem 0 0;
  font-size: 16/37.5rem;
  font-weight: bold;
  color: #fff;
  line-height: normal;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: v-bind(headerBg);
    transform: rotateY(0deg) /* rtl:rotateY(180deg) */;
  }
  &_full-screen {
    padding: 0 36/37.5rem 0 12/37.5rem;
    width: 100%;
    height: 44/37.5rem;
    border-radius: 0;
    font-size: 14/37.5rem;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 6/37.5rem;
    transition: all 0.2s;
    z-index: @zindex-header;
    &_full-screen {
      margin: 14/37.5rem 6/37.5rem;
    }
  }
  &__content {
    position: relative;
    flex: 1;
    z-index: @zindex-hack;
  }
  &__tips {
    word-break: break-word;
    .line-camp(2);
    &_full-screen {
      .line-camp(1);
    }
  }
  &__countdown {
    display: flex;
    align-items: center;
    margin-top: 8/37.5rem;
    height: 20/37.5rem;
    color: #000;
    font-size: 12/37.5rem;
    font-weight: normal;
    .countdown__label {
      margin-right: 4/37.5rem;
    }
  }
  &__img {
    position: relative;
    margin-left: 12/37.5rem;
    width: 126/37.5rem;
    height: 75/37.5rem;
    z-index: @zindex-hack;
    transform: rotateY(0deg) /* rtl:rotateY(180deg) */;
  }
}
</style>
