import mitt from 'mitt'
  
const EventBus = mitt()

EventBus.once = (type, handler) => {
  const fn = (...args) => {
    EventBus.off(type, fn)
    handler(args)
  }
  
  EventBus.on(type, fn)
}

export { 
  EventBus 
}
