<template>
  <div class="type-free-shipping">
    <AddOnItem
      :language="language"
      :abt-info="abtInfo"
      :drawer-config="drawerConfig"
      :query-info="{
        ...queryInfo,
        ...anotherInfo,
      }"
      :type="$attrs.type"
      :sa-info="$attrs.saInfo"
      :flashsale-icon-style="{ '--bg': '#198055' }"
      :config="config"
      :is-bff="isBff"
      @update-cart="handleUpdateCart"
    >
      <template #header>
        <FreeShippingHead
          :full-screen="isFullScreen"
          :promotion="promotion"
        />
      </template>
      <template #footer>
        <FreeShippingFoot
          ref="FreeShippingFoot"
          :promotion="promotion"
          :add-item-type="promotion.addItemType"
          :thresholds="progressList"
          :lure-info="bffIncentiveInfo"
          @back="viewCart"
        />
      </template>
    </AddOnItem>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick } from 'vue'
import { useStore } from 'vuex'
import { EventViewCart, EventListReset } from '../../utils/event.js'
import AddOnItem from '../AddOnItem.vue'
import FreeShippingHead from './Head.vue'
import FreeShippingFoot from './Foot.vue'
import { getPromotionInfo } from '../../utils/fetcher.js'

export default defineComponent({
  name: 'TypeFreeShipping',
  components: {
    AddOnItem,
    FreeShippingHead,
    FreeShippingFoot,
  },
  props: {
    promotion: { // 免邮信息，里面的字段和促销活动有差异
      type: Object,
      default: () => ({}),
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    queryInfo: {
      type: Object,
      default: () => ({}),
    },
    triggerPromotionFetch: {
      type: Boolean,
      default: false,
    },
    isBff: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const { state, getters } = useStore()
    const abtInfo = computed(() => state.abtInfo)
    const language = computed(() => state.language)
    const isFullScreen = computed(() => state.isFullScreen)
    const progressDataPromise = computed(() => state.progressDataPromise)
    const jsonRuleId = computed(() => getters.jsonRuleId)
    return {
      abtInfo,
      language,
      isFullScreen,
      progressDataPromise,
      jsonRuleId,
    }
  },
  data() {
    return {
      isShowProgressUpAnimation: false, // 是否展示过进度提升气派
      lureInfo: null,
    }
  },
  computed: {
    anotherInfo() {
      return {
        goodsPrice: ((this.promotion?.diffPrice?.usdAmount || 0) * 100).toFixed(0), // 凑单差额价格(美分)
        excludeTspIds: this.promotion?.excludeTspIds,
        includeTspId: this.promotion?.includeTspIds,
        jsonRuleId: this.jsonRuleId,
        addOnType: this.promotion?.addOnType,
      }
    },
    progressPercent() {
      return this.promotion?.progressPercent || 0
    },
    progressList() {
      return [{ 
        progressPercent: this.progressPercent,
        transition: { goForward: this.isActivityChange },
      }]
    },
    isActivityChange() {
      return this.promotion?.activityChange == '1'
    },
    incentivePointInstence() {
      return this.$refs.FreeShippingFoot?.incentivePointInstance || {}
    },
    bffIncentiveInfo() {
      return {
        isBff: this.isBff,
        lureInfo: this.lureInfo,
      }
    },
  },
  emits: ['update-promotion'],
  watch: {
    'progressPercent': {
      handler(newV, oldV) {
        if (newV > oldV || this.isActivityChange) {
          // 更新进度和气泡
          this.progressDataPromise?.resolve?.({
            bubbleText: this.getBubbleText(),
          })
        }
      },
      deep: true,
    },
    'triggerPromotionFetch': {
      handler: async function() {
        // fetch promotion info
        try {
          if(this.isBff){
            await this.fetchFreeShippingInfo()
            nextTick(() => {
              EventListReset.notify()
            })
          }
        } catch (error) {
          
        }
      },
      immediate: true,
    }
  },
  methods: {
    getSaExtraData() {
      const extraData = {
        promotion_code: this.promotion?.biData?.promotion_code || '-',
        coupon_change: this.promotion?.activityChange,
        is_satisfied: this.promotion?.biData?.is_satisfied,
        store_code: this.promotion?.biData?.storeCode || '-',
      }
      return extraData
    },
    getBubbleText() {
      if (this.isShowProgressUpAnimation) return ''
      this.isShowProgressUpAnimation = true
      return this.promotion?.bubbleTip
    },
    viewCart(data) {
      EventViewCart.notify(data)
    },
    async fetchFreeShippingInfo() {
      const params = {
        popupInfoId: this.promotion?.promotion_id,
        addItemType: 'free_shipping',
        lurePointCacheInfo: this.incentivePointInstence?.lurePointCacheInfo || '',
        excludeBubbles: Array.from(this.incentivePointInstence?.excludeBubbles || []).join(',') || '',
        freeShippingParams: {
          lastActivityType: this.promotion?.njActivityType, // 上一个免邮活动类型
          lastActivityId: this.promotion?.njActivityId, // 上一个免邮活动id
          lastNowLevel: this.promotion?.now_level, // 上一个运费活动当前档次值
          lastTransportType: this.promotion?.njTransportType, // 上一个运输方式类型
          mallCode: this.queryInfo?.mallCode || '',
          storeCode: this.queryInfo?.storeCode || '',
          freeShippingScope: this.queryInfo.freeShippingScope || (this.queryInfo?.mallCode ? 'mall' : 'platform'),
        }
      }
      const res = await getPromotionInfo(params)
      if(res?.code == 0) {
        const popupInfo = res?.info?.freeShippingPopupInfo
        this.lureInfo = res?.info?.lureInfo
        this.$emit('update-promotion', popupInfo)
      }

    },
    handleUpdateCart() {
      if(this.isBff){
        this.fetchFreeShippingInfo()
      }
    },
  },
})
</script>
<style lang="less" scoped>
.type-free-shipping {
  height: 100%;
}
</style>
